<template>
  <a-modal :title="title" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确定" width="65.5%" :footer="null"
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item>
              <a-button @click="toExport" icon="export" :loading="exportLoading">导出</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <a-table :columns="columns" size="small" :dataSource="list" :bordered='false' :pagination="false" rowKey="commision_id">
        <template slot="index" slot-scope="text, record , index">
            <span v-if="index+1 == list.length">合计</span>
            <span v-else>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
    </a-table>
    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
  </a-modal>
</template>

<script>
import moment from 'moment'
import ranges from "@/common/mixins/ranges"
import tableMixins from '@/common/mixins/table'

const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '员工名称', width: '120px', dataIndex: 'employee_name', key: 'employee_name'},
  { title: '校区名称', dataIndex: 'studio_name', key: 'studio_name',ellipsis: true},
  { title: '合同编号', dataIndex: 'contract_no', key: 'contract_no',ellipsis: true},
  { title: '渠道名称', dataIndex: 'channel_name', key: 'channel_name',ellipsis: true},
  { title: '收入金额', dataIndex: 'income_pirce', key: 'income_pirce',align:'right'},
  { title: '绩效提成', dataIndex: 'commision_price', key: 'commision_price',align:'right'},
  { title: '结算时间', dataIndex: 'created_at', key: 'created_at',align:'right'},
]
    export default {
        name:"recordList",
        inject: ['parent'],
        props: {
            item: Object,
            start_date: String,
            end_date: String,
        },
        mixins: [ tableMixins, ranges ],
        data() {
            return {
                loading: false,
                visible: false,
                confirmLoading: false,
                exportLoading: false,
                columns,
                list: [],
                searchData:{},
                studios:[],
                consultants:[],
                title:'',
            }
        },
        created () {
            this.visible = true
            this.title = `结算记录`
            if(this.item.commision_type == 1){
              this.columns[3] = { title: '上课时间', width: '170px', dataIndex: 'course_time', key: 'course_time',ellipsis: true}
              this.columns[4] = { title: '课程名称', dataIndex: 'course_name', key: 'course_name',ellipsis: true}
            }else{
              this.columns[3] = { title: '合同编号', dataIndex: 'contract_no', key: 'contract_no',ellipsis: true}
              this.columns[4] = { title: '渠道名称', dataIndex: 'channel_name', key: 'channel_name',ellipsis: true}
            }
        },
        methods: {
            async getList() {
                this.loading = true
                this.searchParams.search.employee_id = [this.item.employee_id]
                this.searchParams.search.start_date = this.start_date
                this.searchParams.search.end_date = this.end_date
                let res = await this.$store.dispatch('financeSalaryCalculationCommisionAction', {data:this.searchParams})
                this.list = res.items
                this.list.push({
                  commision_id: -1,
                  commision_price: res._total.commision_price,
                  income_pirce: res._total.income_pirce
                })
                this.pageParams = res._meta
                this.loading = false
            },
            async getStudio() {
              let res = await this.$store.dispatch('searchBelongStudioAction', {})
              this.studios = res.data
            },
            async getConsultant() {
              let res = await this.$store.dispatch('searchConsultantAction', {})
              this.consultants = res.data
            },
            handleOk(){

            },
            handleCancel(){
                this.parent.hideRecord(0)
            },
            handleTime(e){
              if(e.length>0){
                this.searchParams.search.start_date = moment(e[0]).format("YYYY-MM-DD")
                this.searchParams.search.end_date = moment(e[1]).format("YYYY-MM-DD")
              }else{
                this.searchParams.search.start_date = undefined
                this.searchParams.search.end_date = undefined
              }
            },
            async toExport(){
                this.exportLoading = true
                let exportData = {
                    search:this.searchParams.search,
                }
                let res = await this.$store.dispatch('financeSalaryCalculationExportCommisionAction', exportData)
                const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                if ('download' in document.createElement('a')) { 
                    const link = document.createElement('a')
                    link.download = `结算记录.${moment().format('YYYY.MM.DD')}.xlsx`
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href) 
                    document.body.removeChild(link)
                } else { //其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }
                this.exportLoading = false
            },
        },
    }
</script>

<style lang="scss">
  
</style>